@import "~antd/dist/antd.dark.less";

.App {
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
::-webkit-scrollbar {
  display: none;
}

.settings-footer {
  width: 100px;
  height: 38px;
  position: fixed;
  bottom: 0;
  right: 0;
}

.footer-window-controls {
  -webkit-app-region: none;
  width: 40px;
  height: 100%;
  float: right;
  align-self: flex-end;
}
.footer-control-button {
  margin-right: 4px;
  border: none;
}

.body-row {
  display: flex;
  justify-content: space-between;
}

.left-title {
  align-self: flex-start;
}

.right-title {
  align-self: flex-end;
}

.body-bottom-content-left {
  justify-content: flex-start;
  flex-flow: row;
  display: flex;
}

.body-bottom-content-right {
  justify-content: flex-end;
  flex-flow: row;
}

.ant-statistic {
  margin-right: 20px;
}

.body-bottom-content-container {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  justify-content: space-between;
}

.percentage-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.percentage-top {
  font-size: 18px;
}

.percentage-bottom {
  padding-top: 4px;
  padding-bottom: 0;
  font-size: 11px;
}

// Make a region clickable - workaround for dragging
.clickable {
  -webkit-app-region: none;
}

// Remove top padding from ant design drawer
.ant-drawer-body {
  padding: 0 24px;
}

.noDrag {
  -webkit-app-region: none;
}

.header-expand-button {
  height: 100%;
  margin-left: 40%;
}
.header-expand-button button {
  -webkit-app-region: none;
}

.ant-progress-text {
  transform: rotate(-90deg);
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.ant-progress-outer .ant-progress-inner {
  transform: rotate(-180deg);
}

.content-right {
  text-align: right;
}
// Shift
.shift-section {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  position: relative;
}
.shift-section:hover::before,
.show-labels .shift-section::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  height: 20px;
  border: solid 1px rgba(255, 255, 255, 0.18);
  border-bottom: none;
}
.shift-section:hover::after,
.show-labels .shift-section::after {
  content: "Shift";
  position: absolute;
  top: 18px;
  background-color: #000;
  padding: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.18);
}
.vertical-bar {
  width: 90px;
  transform: rotate(90deg);
  padding-top: 40px;
}
.shift-rvu,
.shift-emw {
  width: 45px;
  padding: 45px 0 0 0;
  position: relative;
}
.rvu-goal,
.emw-goal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  display: block;
  color: rgba(255, 255, 255, 0.3);
}
// Study
.study-section {
  position: relative;
  padding: 0 15px;
}
.study-section:hover::before,
.show-labels .study-section::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 20px;
  border: solid 1px rgba(255, 255, 255, 0.18);
  border-left: none;
}
.study-section:hover::after,
.show-labels .study-section::after {
  content: "Study";
  position: absolute;
  background-color: #000;
  padding: 5px;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: -5px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.18);
}
// Daily
.daily-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 35px 0 0 15px;
  margin-top: 10px;
}
.daily-section .ant-statistic {
  margin-right: 20px;
}
.daily-section:hover::before,
.show-labels .daily-section::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  height: 20px;
  border: solid 1px rgba(255, 255, 255, 0.18);
  border-bottom: none;
}
.daily-section:hover::after,
.show-labels .daily-section::after {
  content: "Daily";
  position: absolute;
  top: 18px;
  background-color: #000;
  padding: 5px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.18);
}

.footer-left-info {
  padding-left: 15px;
}

.loading-spinner {
  height: 100vh;
  position: relative;
}
.loading-spinner .ant-spin {
  margin-top: -30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@primary-color: #1DA57A;