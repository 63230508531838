.application-header {
  width: 100%;
  height: 40px;
  color: #aaa;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.application-title {
  align-self: flex-start;
  margin-left: 20px;
}

.header-window-controls {
  -webkit-app-region: none;
  width: 100px;
  height: 100%;
  float: right;
  align-self: flex-end;
}

.application-control-button {
  margin-top: 4px;
  border: none !important;
}

@primary-color: #1DA57A;